<template>
  <div class="systemHomePage">
    <h2>欢迎访问图观信息概览平台-TOP(ToGuide Overview Platform)</h2>
  </div>
</template>

<script>
export default {
  name: 'MgtIndex'
}
</script>

<style scoped lang="scss">
.systemHomePage{
  padding: 20px;
}
</style>
